import React from 'react'
import PropTypes from 'prop-types'
import { useEffect } from 'react';
import { Section } from '../pages/index'
import Layout from '../components/layout/Layout';
import { Button, Linked } from '../components/elements';
import SEO from "@layout/SEO"

import APG from '../images/logos/apg.png'
import Aston from '../images/logos/aston-martin.png'
import Channel from '../images/logos/channel-capital.png'
import ClarkHealthComms from '../images/logos/clarkhealth-comms.png'
import Espayo from '../images/logos/espayo.png'
import Knorr from '../images/logos/knorr.png'
import GourmetHouse from '../images/logos/gourmet-house.png'
import Hotel from '../images/logos/hotel.png'
import Kontor from '../images/logos/kontor.png'
import LogicSpot from '../images/logos/l0gicsp0t.png'
import Poly from '../images/logos/p0ly.png'
import Primark from '../images/logos/primark.png'
import Rag from '../images/logos/rag.png'
import Seedrs from '../images/logos/seedrs.png'
import Wiserfunding from '../images/logos/wiserfunding.png'
import ALondonList from '../images/logos/london-list.png'
import Prose from '../images/logos/prose.png'
import SMIH from '../images/logos/sign-me-in-here-logo.png'

import Hands from '../images/svgs/hand.svg'
import Browser from '../images/svgs/browser.svg'
import Cogs from '../images/svgs/cogs.svg'
import Background from '../images/svgs/background.svg'

const OurWork = props => {


    return (
        <>
         <SEO title="Our Work" description="We value our relationships with our clients, no matter how large or how small the project. We trust them and they trust us. Find out who we work with and see some case studies." />
        <Layout>

            <Section style={{
                backgroundImage: `linear-gradient(135deg, #006875 0%, #00404A 100%)`,
            }} fullWidth={true} >

                <div className="w-full min-h-screen flex flex-col justify-center px-8 ">
                    <div style={{
                        transform: 'rotate(3deg)',
                    }} className="bg-primary py-8 px-4 inline-block max-w-5xl mx-auto self-center ">
                        <h1 style={{ transform: 'rotate(-3deg)' }} className="text-center self-center w-full text-gray-100 text-3xl md:text-4xl lg:text-5xl px-2 md:px-12">Collaborating with our clients, <br className="hidden md:inline-block" /> never feels like work </h1>
                    </div>
                    <ul className="circles" >
                        <li><img src={APG} alt="Client APG" /></li>
                        <li><img src={Aston} alt="Client Aston Martin" /></li>
                        <li><img src={Poly} alt="Client Poly" /></li>
                        <li><img src={Kontor} alt="Client K0ntor" /></li>
                        <li><img src={Primark} alt="Client Primark" /></li>
                        <li><img src={Rag} alt="Client Rag" /></li>
                        <li><img src={Wiserfunding} alt="Client Wiserfunding" /></li>
                        <li><img src={Hotel} alt="Client Hotel" /></li>
                        <li><img src={LogicSpot} alt="Client L0gicspot" /></li>
                        <li><img src={Seedrs} alt="Client Seedrs" /></li>
                    </ul>
                </div>
            </Section>
            <Section fullHeight={false} className="py-12 lg:pt-24 lg:pb-4 justify-center flex lg:flex-row flex-col">
                <div className="lg:w-1/3 mb-4">
                    <div className="mx-2 md:mx-8 bg-gray-200 hover:shadow-3xl transition-shadow duration-500  py-8 rounded-lg ">
                        <img className="mx-auto h-32" src={Browser} alt="Browser" />
                        <h2 className="text-center mt-4 text-xl">Start with a vision</h2>
                    </div>
                </div>
                <div className="lg:w-1/3 mb-4">
                    <div className="mx-2 md:mx-8 bg-gray-200 hover:shadow-3xl transition-shadow duration-500 py-8  rounded-lg ">
                        <img className="mx-auto h-32" src={Cogs} alt="Cogs" />
                        <h2 className="text-center mt-4 text-xl">Bring it to life</h2>
                    </div>
                </div>
                <div className="lg:w-1/3 mb-4">
                    <div className="mx-2 md:mx-8 bg-gray-200 hover:shadow-3xl transition-shadow duration-500 py-8  rounded-lg ">
                        <img className="mx-auto h-32" src={Hands} alt="Hands" />
                        <h2 className="text-center mt-4 text-xl">Create resources for growth</h2>
                    </div>
                </div>
            </Section>

            <Section fullHeight={false} fullWidth className="px-4 md:py-12 pb-24 flex-col justify-center">
                <p className="border-l-4 pl-4 md:border-none md:pl-0 border-highlight md:text-center max-w-4xl mx-auto text-2xl leading-loose my-4">
                    We work with clients in a multitude of different areas across a large variety of spaces - very rarely does any two projects look the same. By working with our clients to craft a strategy behind their vision we can assist in directly helping them grow, improve or provide the foundations for them to do it themselves.
                </p>
                <p className="text-center max-w-4xl mx-auto text-2xl leading-loose my-4">
                    Versatility is the key to everything we do.
                </p>
                <Button linkTo="/contact" className="mx-auto py-3 mt-8 px-6 text-xl">Collaborate with us today</Button>
            </Section>

            <Section fullHeight={false} fullWidth className="relative overflow-hidden">
                
                <div className="max-w-6xl mx-auto grid sm:grid-cols-2 lg:grid-cols-3 lg:grid-rows-2 gap-8 lg:gap-2 pt-12 pb-24 lg:gap-8 px-4 lg:px-2 relative z-10">



                    {
                        [
                            {
                                header: "Wiserfunding",
                                description: "Wiserfunding is a fintech startup based out of London and Milan, we provided a complete redesign of their website from the ground up using modern JAM Stack technology. We also maintain a strong ongoing relationship, providing design, web development, digital presence consultancy and content production.",
                                logo: Wiserfunding,
                                linkTo: ""
                            },
                            {
                                header: "Knorr",
                                description: "Working with a big brand like Knorr was a great experience. Strategic partnerships allowed us to create a unique website and experience for the client. We worked closely with Knorr parent company Unilever to achieve the required results and build a fantastic end product.",
                                logo: Knorr,
                                linkTo: ""
                            },

                            {
                                header: "A London List",
                                description: "A London List wanted a website that contained all the best places in London to eat, drink and just generally explore. We pitched a modern JAM Stack framework that allowed them to best capitalise on their unique content and improve their SEO and general company visibility.",
                                logo: ALondonList,
                                linkTo: ""
                            },
                            {
                                header: "Grand Hotel Fasano",
                                description: "Travelling to the hotel in Italy, set on the stunning lake Gardia, we worked closely with management to produce a stunning video for the hotel that they have used across large swathes of their social media and in their website. The focus was on the feeling of staying at the hotel and bringing that across to the audience.",
                                logo: Hotel,
                                linkTo: ""
                            },
                            // {
                            //     header: "Prose",
                            //     description: "A writing and blogging application, connecting to headless CMSs in order to allow writers, marketers or anyone at all to publish and share pieces of content with just the click of a button. The focus for Prose is to make the writing experience as seamless and enjoyable as possible, removing unnecessary complexity from the users experience.",
                            //     logo: Prose,
                            //     linkTo: ""
                            // },
                            {
                                header: "Sign Me In Here",
                                description: "A track and trace program helping venues check their guests and patrons in on arrival in the simplest and most convenient way. A full stack SAAS application designed, architected and built in house - this was a great and current project.",
                                logo: SMIH,
                                linkTo: ""
                            },
                            {
                                header: "Aston Martin & APG Stables",
                                description: "An impact piece; centred on tying in the equestrian aspects with the raw power of the Aston Martin DB Vantage. Set to deep music it instantly captures the audiences attention and draws them in. This piece of video content was created for APG Stables with Aston Martin.",
                                logo: Aston,
                                linkTo: ""
                            },
                        ].map(({ header, description, logo, linkTo }) => {
                            return <div key={header} className="p-6 rounded-lg px-6 bg-primary text-gray-100 transition-shadow hover:shadow-3xl duration-500 h-full flex flex-col" >
                                <div className="bg-gray-200 mx-auto rounded-full mb-4 p-4 h-48 w-48 flex flex-col justify-center">
                                    <img
                                        className=""
                                        src={logo}
                                        alt={`${header} Logo`} />
                                </div>
                                <h2 className="text-2xl mb-2 font-normal">{header} </h2>
                                <p className="leading-loose mb-2 font-light">{description}</p>
                                <div className="flex flex-grow">
                                    { linkTo ? 
                                    <Linked className="font-bold hover:text-highlight self-end " linkTo="/">Read more...</Linked> : <div className="font-bold self-end">Case study coming soon...</div>
                                    }
                                </div>
                            </div>
                        })}
                </div>
                <div
                    className="w-full h-full absolute z-0"
                    style={{
                        backgroundImage: `url(${Background})`,
                        backgroundSize: 'cover'
                    }}
                ></div>
                {/* <img className="w-full h-full absolute z-0" src={Background} alt="" /> */}
            </Section>
        </Layout>
        </>

    )
}

OurWork.propTypes = {

}

export default OurWork
